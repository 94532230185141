jQuery(function($){
    objectFitImages();
    svg4everybody();

    // IE shaky scroll fix
    // See: https://coderwall.com/p/hlqqia/ie-fix-for-jumpy-fixed-bacground
    if (navigator.userAgent.match(/Trident\/7\./)) { // if IE
      $('body').on("mousewheel", function () {
        // remove default behavior
        event.preventDefault();

        //scroll without smoothing
        var wheelDelta = event.wheelDelta;
        var currentScrollPosition = window.pageYOffset;
        window.scrollTo(0, currentScrollPosition - wheelDelta);
      });
    }

    var iOSMatch = navigator.appVersion.match(/iPhone/) || navigator.appVersion.match(/iPad/);
    var versionMatch = navigator.appVersion.match(/iPhone OS ([0-9_]+) /);
    var version = versionMatch && versionMatch.length ? versionMatch.pop().split('_')[0] : false;
    var IEVersion = detectIE();
    var isIEandNotEdge = IEVersion && IEVersion < 12;
    var isEdge = IEVersion && IEVersion >= 12;

    if (iOSMatch === 'iPhone OS' && version !== '10')  {
        document.write('<meta http-equiv="Content-Security-Policy" content="default-src * \'unsafe-inline\' \'unsafe-eval\' data: blob:;">');
    }

    if (typeof TextOverflow !== undefined) {
        TextOverflow.prototype.builder();
    }

    if (isIEandNotEdge) {
        $('html').attr('data-is-ie', IEVersion);
    } else if (isEdge) {
        $('html').attr('data-is-edge', IEVersion);
    }
});//project js